import React, {Component} from 'react';
import '../index.css';

class BackgroundAndLogo extends Component {
    render() {
        return (
            <div>
                <div className="relative w-full h-screen overflow-hidden">
                    <div className="absolute inset-0">
                        <img src="https://i.imgur.com/Gac7ZUz.png" alt="background.webp"
                             className="w-full h-full object-cover object-center filter blur-sm"/>
                    </div>
                    <div className="relative flex flex-col items-center justify-center h-full text-white text-center">
                        <img src="https://i.imgur.com/fDOW98K.png" alt="blackNinjaLive.webp" className="mb-16 min-w-50 h-auto transition ease-in-out hover:scale-110 drop-shadow-md animate-slidein300 opacity-0"/>
                        <p className="text-4xl font-bold text-cyan-400 transition-all hover:text-cyan-500 my-4 animate-slidein500 opacity-0">
                            <span className="main-ip" id="main-ip" onClick={copyIP}>BLACKNINJA.LIVE</span>
                        </p>
                        <p className="text-base text-wrap w-3/4 md:w-1/4 animate-slidein700 opacity-0">Dein Minigames Netzwerk, wir arbeiten fest
                            mit der Community zusammen, um ein best mögliches Spiel Erlebnis zu bieten.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

function copyIP() {
    const copyText = "BlackNinja.Live";
    navigator.clipboard.writeText(copyText).then(r =>
        setText()
    )
}

function setText() {
    document.getElementById("main-ip").innerHTML = "Kopiert!"
    setTimeout(() => {
        document.getElementById("main-ip").innerHTML = "BLACKNINJA.LIVE"
    }, 1000 * 2);
}

export default BackgroundAndLogo;