import React, {Component} from 'react';

class Gamemodes extends Component {
    render() {
        return (
            <div>
                <div
                    className="container mx-auto px-4 py-8 bg-neutral-700 rounded-3xl space-y-8 border-4 border-neutral-600">

                    <div className="mb-4">
                        <h2 className="text-4xl font-bold text-cyan-400 text-center drop-shadow-md">Spielmodies</h2>
                        <p className="text-neutral-400 text-center font-bold">Was wir zu bieten haben!</p>
                    </div>

                    <div
                        className="flex flex-col lg:flex-row mx-10 items-center lg:items-start rounded-3xl bg-neutral-800 drop-shadow-lg transition ease-in-out hover:scale-110">
                        <div className="w-full lg:w-2/5 flex justify-center items-center p-8">
                            <img
                                src="https://i.imgur.com/trrOI9k.png"
                                alt="knockout.webp"
                                className="object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                        </div>
                        <div className="w-full lg:w-3/5 p-8 text-white">
                            <h2 className="text-3xl font-bold mb-4 text-center text-cyan-400">KnockOut</h2>
                            <p className="text-2xl">Kämpfe mit einem Knockback Stick und Schlage deine Gegner in die
                                Tiefe!</p>
                        </div>
                    </div>

                    <div
                        className="flex flex-col lg:flex-row mx-10 items-center lg:items-start rounded-3xl bg-neutral-800 drop-shadow-lg transition ease-in-out hover:scale-110">
                        <div className="w-full lg:w-3/5 p-8 text-white">
                            <h2 className="text-3xl font-bold mb-4 text-center text-cyan-400">HideAndSeek</h2>
                            <p className="text-2xl">Verstecke dich als Hider, ärgere deine Mitspieler mit Sticheleien
                                und
                                hoffe nicht von den Seekern gefunden zu werden.</p>
                        </div>
                        <div className="w-full lg:w-2/5 flex justify-center items-center p-8">
                            <img
                                src="https://i.imgur.com/6FCtXGy.png"
                                alt="hideandseek.webp"
                                className="object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                        </div>
                    </div>

                    <div
                        className="flex flex-col lg:flex-row mx-10 items-center lg:items-start rounded-3xl bg-neutral-800 drop-shadow-lg transition ease-in-out hover:scale-110">
                        <div className="w-full lg:w-3/5 p-8 text-white">
                            <h2 className="text-3xl font-bold mb-4 text-center text-cyan-400">SurvivalGames</h2>
                            <p className="text-2xl">Finde Ausrüstung in Truhen und
                                kämpfe gegen andere Spieler um dein Leben.</p>
                        </div>
                        <div className="w-full lg:w-2/5 flex justify-center items-center p-8">
                            <img
                                src="https://i.imgur.com/crQJtzA.png"
                                alt="hideandseek.webp"
                                className="object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Gamemodes;