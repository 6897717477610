import React, {Component} from 'react';

class Navbar extends Component {
    render() {
        return (
            <div className="w-full bg-neutral-900 border-black border-4 border-t-0 border-x-0 fixed z-10 bg-opacity-80 filter backdrop-blur-2xl">
            <div className="flex flex-row mx-auto max-w-3xl justify-center w-full">
                <div className="flex flex-row justify-between gap-12 xl:gap-48 p-1">
                    <div
                        className="cursor-pointer my-auto text-gray-900 rounded-lg group bg-gradient-to-br from-lime-500 to-green-500
                        group-hover:from-lime-500 group-hover:to-green-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none
                        focus:ring-lime-200 dark:focus:ring-lime-800 px-4 py-2 font-bold hover:ring-4">
                        <a href="https:///www.BlackNinja.Live/smp">SMP</a>
                    </div>
                    <div>
                        <a href="https:///www.BlackNinja.Live/"><img className="my-2 w-40 md:w-15 xl:w-40 h-auto transition ease-in-out hover:scale-110"
                                                               src="https://i.imgur.com/fDOW98K.png"
                                                               alt="logo.webp"/></a>
                    </div>
                    <div
                        className="cursor-pointer my-auto text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500
                        group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none
                        focus:ring-cyan-200 dark:focus:ring-cyan-800 px-4 py-2 font-bold hover:ring-4">
                        <a href="https:///www.blackninja.live/blog">Blog</a>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Navbar;