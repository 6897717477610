import React, {Component} from 'react';
import Navbar from "../components/base/Navbar";
import Footer from "../components/base/Footer";
import { faDownload } from '@fortawesome/fontawesome-free-solid'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Smp extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar/>

                <div className="my-8 text-center">
                    <h2 className="text-4xl font-bold drop-shadow-md bg-gradient-to-r from-cyan-400 to-lime-400 inline-block text-transparent bg-clip-text">SMP
                        Download</h2>
                    <p className="text-neutral-400 text-center font-bold">Lade dir alle SMP Welten!</p>
                </div>

                <div className="p-8 px-4 lg:px-24 my-8 justify-center grid gap-x-0 sm:grid-cols-4 lg:grid-cols-4">

                    <div
                        className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 p-5 mx-5 my-8 transition ease-in-out hover:scale-110">
                        <img src="https://i.imgur.com/aJ0fkTK.png"
                             alt="smp1.webp"
                             className="w-120 h-60 ml-auto mr-auto object-cover max-h-96 drop-shadow-md mb-4 "/>

                        <p className="text-2xl text-cyan-400 text-center font-bold ">SMP V1</p>
                        <p className="text-neutral-400 text-center font-bold">10. Juni 2021</p>

                        <p className="text-xl text-red-600 text-center mt-4 font-bold">Welt nicht mehr Vorhanden!</p>
                    </div>

                    <div
                        className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 p-5 mx-5 my-8 transition ease-in-out hover:scale-110">
                        <img src="https://i.imgur.com/nYWtUWM.png"
                             alt="smp2.webp"
                             className="w-120 h-60 ml-auto mr-auto object-cover max-h-96 drop-shadow-md mb-4"/>

                        <p className="text-2xl text-cyan-400 text-center font-bold ">SMP V2</p>
                        <p className="text-neutral-400 text-center font-bold">12. November 2021</p>

                        <div className="text-center cursor-pointer my-4 text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500
                        group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none
                        focus:ring-cyan-200 dark:focus:ring-cyan-800 px-0 py-2 font-bold hover:ring-4 drop-shadow-lg">
                            <a href="">Download
                                <FontAwesomeIcon className="mx-2" icon={faDownload}/>
                            </a>
                        </div>
                    </div>

                    <div
                        className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 p-5 mx-5 my-8 transition ease-in-out hover:scale-110">
                        <img src="https://i.imgur.com/TxsKi4Q.png"
                             alt="smp3.webp"
                             className="w-120 h-60 ml-auto mr-auto object-cover max-h-96 drop-shadow-md mb-4"/>

                        <p className="text-2xl text-cyan-400 text-center font-bold ">SMP V3</p>
                        <p className="text-neutral-400 text-center font-bold">16. Februar 2023</p>

                        <div className="text-center cursor-pointer my-4 text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500
                        group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none
                        focus:ring-cyan-200 dark:focus:ring-cyan-800 px-0 py-2 font-bold hover:ring-4 drop-shadow-lg">
                            <a href="">Download
                                <FontAwesomeIcon className="mx-2" icon={faDownload}/>
                            </a>
                        </div>
                    </div>

                    <div
                        className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 p-5 mx-5 my-8 transition ease-in-out hover:scale-110">
                        <img src="https://i.imgur.com/35sar8D.png"
                             alt="smp4.webp"
                             className="w-120 h-60 ml-auto mr-auto object-cover max-h-96 drop-shadow-md mb-4"/>

                        <p className="text-2xl text-cyan-400 text-center font-bold ">SMP V4</p>
                        <p className="text-neutral-400 text-center font-bold">28. Juni 2024</p>

                        <p className="text-xl text-green-500 text-center mt-4 font-bold">SMP läuft akutell noch...</p>
                    </div>

                </div>

                <p className="text-neutral-400 text-xl text-center font-bold italic">Mehr Infos findest du im Discord!</p>
                <div className="text-center mt-6">
                    <a href="https://dc.blackninja.live/" className="my-4 px-6 text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500
                        group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none
                        focus:ring-cyan-200 dark:focus:ring-cyan-800 py-2 font-bold hover:ring-4 drop-shadow-lg">Discord
                        <svg className="w-5 h-5 ml-2 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 21 16">
                            <path
                                d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"/>
                        </svg>
                    </a>
                </div>

                <div className="w-full m-12"></div>

                <Footer/>

            </div>
        );
    }
}

export default Smp;