import React, {Component} from 'react';
import Navbar from "../components/base/Navbar";
import Footer from "../components/base/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer } from '@fortawesome/fontawesome-free-solid'

class Error extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar/>

                <div className="p-8 px-4 lg:px-24 w-full flex flex-col">
                    <div className="flex flex-row w-full px-8 bg-neutral-700 rounded-t-3xl text-white font-bold">
                        <div className="py-4 flex flex-col">
                            <div className="text-lg uppercase">BlackNinja.Live</div>
                            <div className="text-sm text-cyan-400 uppercase font-bold">» Server Error</div>
                        </div>
                    </div>
                    <div className="w-full px-8 bg-neutral-700/[0.5] rounded-b-3xl py-4">
                        <div className="text-base mt-6 mb-6 text-gray-200">
                        <span className="prose prose-invert">
                            <div>
                                <h1 className="font-bold text-3xl">404: Page Not Found
                                    <FontAwesomeIcon className="mx-2" icon={faServer}/>
                                </h1>
                                <br/>
                                <p className="text-xl">Deine Seite konnte nicht gefunden werden!</p>
                                <br/>
                                <p className="text-xl">Sollte dies ein Fehler sein, kontaktieren sie unverzüglich einen</p>
                                <p className="text-xl">Server Administrator oder melde den Fehler im Discord!</p>
                                <br/>
                                <hr/>
                                <br/>
                                <br/>
                                <p>Was Kannst du machen:</p>
                                <br/>
                                <br/>
                            </div>

                            <div className="text-base mt-6 mb-6 text-gray-200 inline">
                                <a href="" className="underline hover:text-cyan-400 cursor-pointer">Startseite</a>
                                <a onClick={reloadPage} className="mx-8 underline hover:text-cyan-400 cursor-pointer">Seite neuladen</a>
                                <a onClick={backPage} className="underline hover:text-cyan-400 cursor-pointer">Zurück zur letzten Seite</a>
                            </div>

                            <br/>
                            <br/>

                        </span>
                        </div>
                    </div>
                </div>

                <Footer/>

            </div>
        );
    }
}

function reloadPage() {
    window.location.reload();
}

function backPage() {
    window.history.back();
}

export default Error;