import React, {Component} from 'react';
import Navbar from "../../components/base/Navbar";
import Footer from "../../components/base/Footer";

class Blog2 extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar/>

                <div className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 mx-12 my-8 p-5 px-24 justify-center">

                    <div className="text-base mt-6 mb-6 text-neutral-200">
                            <span className="prose prose-invert">
                                <h1 className="text-4xl text-center font-bold">Neu Eröffnung #2</h1>
                                <p className="text-neutral-400 text-center font-bold">29. März 2024</p>
                                <br/>
                                 <img
                                     src="https://i.imgur.com/Gac7ZUz.png"
                                     alt="lobby.webp"
                                     className="w-full h-full object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400 block ml-auto mr-auto"/>

                                <br/>
                                <div className="px-24 text-xl">
                                    <p>
                                        Das BlackNinja.live Netzwerk geht in die nächste Runde! Seit einiger Zeit befindet sich
                                        unser Netzwerk aufgrund technischer Probleme in der Wartung. Letztendlich haben wir (blackNinja46) uns
                                        für eine neue Server Hardware entschieden. Dementsprechend wird es auch ein großes Update geben, welches
                                        wir euch im Laufe der Zeit in einzelnen Teasern mitteilen möchten.
                                    </p>
                                    <br/>
                                    <p>
                                        Der erste Teaser hier wäre, dass wir euch eine komplett neue Lobby präsentieren dürfen. Dies ist aber nur der
                                        erste von 3 weiteren Teasern die wir euch mitteilen möchten.
                                    </p>
                                    <br/>
                                    <p>Viele Grüße!</p>
                                    <p>Ihr BN-Server Team</p>
                                    <br/>
                                </div>
                            </span>
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }
}

export default Blog2;