import React, {Component} from 'react';
import Navbar from "../components/base/Navbar";
import Footer from "../components/base/Footer";

class Dsgvo extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar />

                <div className="p-8 px-4 lg:px-24 w-full flex flex-col">
                    <div className="flex flex-row w-full px-8 bg-neutral-700 rounded-t-3xl text-white font-bold">
                        <div className="py-4 flex flex-col">
                            <div className="text-lg uppercase">BlackNinja.Live</div>
                            <div className="text-sm text-cyan-400 uppercase font-bold"> » Datenschutzerklärung</div>
                        </div>
                    </div>


                    <div className="w-full px-8 bg-neutral-700/[0.5] rounded-b-3xl py-4">
                        <div className="text-base mt-6 mb-6 text-gray-200">
                            <span className="prose prose-invert">
                                <br/>
                                <div>
                                    <strong className="text-3xl">Datenschutzerklärung</strong>

                                    <p>Willkommen auf unserem Minecraft-Server! Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und möchten Sie daher umfassend darüber informieren, welche Daten wir erheben und wie wir diese verarbeiten und nutzen. Mit der Nutzung unseres Servers stimmen Sie der folgenden Datenschutzerklärung zu.</p>
								    <br/>
								    <strong className="text-2xl">Definitionen</strong>
                                    <br/>
								    » <strong>Server-Leitung:</strong> Hiermit ist der Besitzer blackNinja46 gemeint.<br/>
								    » <strong>Server-Team:</strong> Dies umfasst alle Teammitglieder unseres Servers.
								    <br/>
								    <strong className="text-2xl">Erhobene Daten und deren Verwendung</strong>

                                    <strong className="text-3xl">Verbindungsdaten</strong><br/>
								    <p>» <strong>Gespeicherte Daten:</strong> Bei jeder Verbindung zu unserem Server speichern wir Ihre IPv4-Adresse und die Domain der Verbindung.<br/>
								    » <strong>Sichtbarkeit:</strong> Diese sensiblen Daten sind ausschließlich für die Server-Leitung einsehbar.</p>
								    <br/>

                                    <strong className="text-3xl">Minecraft-bezogene Daten</strong>
								    <p>» <strong>UUID und Name:</strong> Diese Daten sind für alle Nutzer unseres Servers sichtbar.<br/>
								    » <strong>Spielstatistiken:</strong> Die Statistiken aller Minigames sind für jeden sichtbar.<br/>
								    </p>
								    <br/>

                                    <strong className="text-2xl">Discord-Daten</strong>
								    <p><strong>Tickets und Bewerbungen:</strong> Auf unserem Discord-Server werden alle Tickets und Bewerbungen gespeichert. Diese Daten sind nur für die Server-Leitung sichtbar.</p>
								    <br/>

                                    <strong className="text-3xl">Zweck der Datenerhebung</strong>
								    <p>Wir erheben und verarbeiten diese Daten, um den reibungslosen Betrieb des Servers zu gewährleisten, die Spielqualität zu verbessern und ein sicheres Spielerlebnis zu bieten. Die gespeicherten Daten helfen uns dabei, technische Probleme zu identifizieren und zu lösen sowie Betrugsversuche und Missbrauch zu verhindern.</p>
								    <br/>
								    <strong className="text-3xl">Datenschutzrechte</strong>
								    <p>Sie haben jederzeit das Recht, Auskunft über Ihre bei uns gespeicherten Daten zu erhalten und die Berichtigung, Löschung oder Sperrung dieser Daten zu verlangen, sofern keine gesetzlichen Aufbewahrungspflichten dem entgegenstehen. Wenden Sie sich hierfür bitte an die Server-Leitung.</p>
								    <br/>
								    <strong className="text-3xl">Kontakt</strong>
								    <p>Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen wenden Sie sich bitte an:</p>
								    <p><strong>Server-Leitung: blackNinja46</strong><br/>
								    <strong>E-Mail:</strong> business@blackNinja.live</p>

                                    <br/>
								    <p>Diese Datenschutzerklärung kann jederzeit von uns geändert werden. Über wesentliche Änderungen werden wir Sie rechtzeitig informieren.</p>

								    <p>Vielen Dank für Ihr Vertrauen und viel Spaß auf unserem Minecraft-Server!</p>

								    <hr/>
								    <p><em>Datum der letzten Aktualisierung: 28.08.2024</em></p>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>

                <Footer/>

            </div>
        );
    }
}

export default Dsgvo;