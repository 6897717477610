import Main from "./pages/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Rules from "./pages/Rules";
import Impressum from "./pages/Impressum";
import Dsgvo from "./pages/Dsgvo";
import Error from "./pages/Error";
import Blog from "./pages/Blog";
import Blog2 from "./pages/blogs/Blog2";
import Blog3 from "./pages/blogs/Blog3";
import React from "react";
import Smp from "./pages/Smp";
import Blog1 from "./pages/blogs/Blog1";

function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route index element={
                    <Main/>
                }/>
                <Route path="/regelwerk" element={
                    <Rules/>
                }/>
                <Route path="/impressum" element={
                    <Impressum/>
                }/>
                <Route path="/dsgvo" element={
                    <Dsgvo/>
                }/>
                <Route path="/*" element={
                    <Error/>
                }/>
                <Route path="/blog" element={
                    <Blog/>
                }/>
                <Route path="/blog" element={
                    <Blog/>
                }/>
                <Route path="/smp" element={
                    <Smp/>
                }/>
                { /* Blogs */ }
                <Route path="/blog-1" element={
                    <Blog1/>
                }/>
                <Route path="/blog-2" element={
                    <Blog2/>
                }/>
                <Route path="/blog-3" element={
                    <Blog3/>
                }/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
