import React, {Component} from 'react';
import Navbar from "../components/base/Navbar";
import Footer from "../components/base/Footer";

class Rules extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar />

                <div className="p-8 px-4 lg:px-24 w-full flex flex-col">
                    <div className="flex flex-row w-full px-8 bg-neutral-700 rounded-t-3xl text-white font-bold">
                        <div className="py-4 flex flex-col">
                            <div className="text-lg uppercase">BlackNinja.Live</div>
                            <div className="text-sm text-cyan-400 uppercase font-bold"> » Regelwerk</div>
                        </div>
                    </div>


                    <div className="w-full px-8 bg-neutral-700/[0.5] rounded-b-3xl py-4">
                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §1 Allgemeines
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Jegliche Beleidigungen, Respektlosigkeiten, Diskriminierungen, rassistische Aussagen
                                o.Ä.,
                                Drohungen oder gezielte Provokationen sind zu unterlassen.<br/>
                                - Generell gilt hier ein freundlicher und friedlicher Umgang. Wer sich daran nicht hält,
                                ist auf
                                dem BlackNinja.Live Netzwerk nicht erwünscht.<br/>
                                - Alle Regeln können im Verlauf des Spiels angepasst werden. Regeländerungen ergeben
                                sich häufig
                                aus Handlungen der Spieler.<br/>
                                Es ist verboten, die Anweisungen und Entscheidungen eines Teammitglieds durch das Fragen
                                eines
                                weiteren Teammitglieds zu umgehen, ohne den Zweiten über die Entscheidung des Ersten zu
                                benachrichtigen.<br/>
                                - Community-Events jeglicher Art (Drop-, Abbau-, XP-, Container-, etc. Events) sind
                                jedem
                                Spieler gestattet. Sollte der Server durch ein solches Event zum Laggen und somit zur
                                Unspielbarkeit getrieben werden, werden die Verantwortlichen des Events bestraft.<br/>
                                - Das Server-Team behält sich das Recht vor, Verhaltensweisen von Spielern zu bestrafen,
                                auch,
                                wenn diese nicht explizit in den Serverregeln aufgeführt sind.<br/>
                                - AFK-Farming und das Benutzen von AFK-Brunnen ist nicht erlaubt dies kann nach Ermessen
                                des
                                Server-Team bestraft werden.
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §2 Chatverhalten und grundsätzliches Verhalten
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Spamming (das (schnelle) wiederholen einer Nachricht) ist untersagt. Das Gleiche gilt
                                auch für
                                das
                                mehrfache Nutzen von GROßBUCHSTABEN (Capslock).<br/>
                                - Jegliche unerlaubte Werbung ist strengstens verboten und wird bestraft. Das gilt im
                                Chat, auf
                                unserem Discord, hier auf der Webseite, auf Schildern und auf anderen Wegen.<br/>
                                - Jegliche Beleidigung ist strengstens verboten und wird bestraft.<br/>
                                - Trolling (das Verarschen) von Spieler:innen und Teammitglieder:innen ist hier
                                verboten. (Es
                                gilt
                                das Ermessen der Spielerschaft und der Teammitglieder)<br/>
                                - Jeglicher Handel mit Echtgeld ist strengstens untersagt und führt zu einem permanenten
                                Hausverbot.<br/>
                                - Das Austauschen von sensiblen Daten wie Adressen, Telefonnummern ist zu unterlassen.
                                Seid
                                vorsichtig, wem ihr eure Daten gebt. Ein Teammitglied wird dich nicht nach einer
                                Telefonnummer
                                oder
                                einer Adresse fragen.
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §3 Clientmodifikationen
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Jegliche Clientmodifikationen, welche eine*r Spieler*in einen Vorteil gegenüber
                                anderen Spieler*innen verschafft, sind verboten.<br/>
                                - Clientmodifikationen wie LabyMod oder BAC sind in ihrer unmodifizierten Version
                                grundsätzlich erlaubt.<br/>
                                - Auto Clicker sind strengstens untersagt. Darunter zählen: Progamme, umlegung auf
                                andere Taste mit pysischer Sicherung. Z.b. Klicken auf Leertaste und ein die Taste
                                beschweren.<br/>
                                - X-Ray oder ähnliche Modifikationen sind strengstens verboten.<br/>
                                - Jegliches Umgehen von Serverfunktionen und Servereinstellungen ist strengstens
                                untersagt.
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §4 Sicherheitslücken, Bugs &amp; Systemfehler
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Sollte eine*r Spieler*in ein Bug/Systemfehler oder eine Sicherheitslücke o.Ä.
                                auffallen, muss diese/r sofort dem Support-Team gemeldet werden. <br/>
                                - Das vorsätzliche ausnutzen von einer Sicherheitslücke o.Ä. ist verboten und wird
                                bestraft. <br/>
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §5 Weisungsrecht
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Jeglichen Anweisungen der Teammitglieder*innen und der Administration / Verwaltung ist
                                Folge zu leisten. Respektlosigkeit oder misachtung ist verboten.
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §6 Bauwerkeregelung &amp; Griefing
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                Generell wollen wir in Bauwerke o.Ä. nicht einschreiten, dennoch gelten hier folgende
                                Regelungen: <br/>
                                - Sämtliche Arten von Userfallen sind nicht erlaubt. Dazu gehört auch das Graben von
                                Löchern, die beim Hineinfallen größeren Schaden verursachen oder sogar zum Tod führen
                                können.<br/>
                                - Anstößige / sexuelle / rassistische / gegen das deutsche Recht verstoßende o.Ä.
                                Bauwerke sind verboten.<br/>
                                - Wir sind ein familienfreundlicher Minecraft-Server, weshalb wir die Spielerschaft
                                auffordern solche Bauwerke zu unterlassen.<br/>
                                - Griefing (zerstören von Bauwerken, die einem selbst nicht gehören) ist verboten und
                                wird bestraft.<br/>
                                - In den Farmwelten soll nicht gebaut werden. (Diese werden regelmäßig
                                resettet/gelöscht. Demnach gibt es keine Rückerstattung.)
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §7 Skins, Accounts &amp; Verbindungsregelungen
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Anstößige / sexuelle / rassistische / gegen das deutsche Recht verstoßende o.Ä. Skins
                                sind verboten. <br/>
                                - Wir sind ein familienfreundlicher Minecraft-Server, weshalb wir die Spielerschaft
                                aufforden einen passenden Skin zu wählen.<br/>
                                - Das Nutzen von mehreren Accounts (Multiaccounting) im sinne von Farming (AFK Farmen)
                                ist verboten.<br/>
                                - Das Nutzen von z.B. VPN's ist verboten.<br/>
                                - Jeder ist für seinen/ihren Account selbst verantwortlich.
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §8 Berechtigungsregelung
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Das Ausnutzen der eigenen Berechtigungen als Teammitglied ist verboten.<br/>
                                - Sobald Spieler durch Teammitglieder:innen bevorzugt werden, droht ein Ausschluss aus
                                dem Serverteam.<br/>
                                - Administration sind von diesen Regeln ausgeschlossen.<br/>
                            </div>
                        </div>

                        <div className="mb-2 ">
                            <ul className="flex text-lg font-semibold text-cyan-400 justify-start flex-wrap items-center rounded-t p-2 bg-neutral-900">
                                §9 Meldepflicht
                            </ul>

                            <div
                                className="text-md font-normal w-full bg-neutral-800/[0.5] p-2 border border-neutral-800 rounded-b text-neutral-100">
                                - Jeglicher Regelverstoß ist zu melden. Dabei spielt die Beziehung zwischen
                                Regelmissbraucher und Zeug*in keine Rolle.
                            </div>
                        </div>

                    </div>
                </div>

                <Footer/>

            </div>
        );
    }
}

export default Rules;