import React, {Component} from 'react';
import Navbar from "../../components/base/Navbar";
import Footer from "../../components/base/Footer";

class Blog1 extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar/>

                <div className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 mx-12 my-8 p-5 px-24 justify-center">

                    <div className="text-base mt-6 mb-6 text-neutral-200">
                            <span className="prose prose-invert">
                                <h1 className="text-4xl text-center font-bold">Frohe Weihnachten! #1</h1>
                                <p className="text-neutral-400 text-center font-bold">24. Dezember 2023</p>
                                <br/>
                                 <img
                                     src="https://i.imgur.com/VqE70MB.png"
                                     alt="lobby.webp"
                                     className="w-full h-full object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-red-500 block ml-auto mr-auto"/>

                                <br/>
                                <div className="px-24 text-xl">
                                    <h2 className="text-4xl mb-4 font-bold drop-shadow-md bg-gradient-to-l from-red-500 via-red-100 via-50% to-red-500 inline-block text-transparent bg-clip-text">Frohe Weihnachten! </h2>
                                    <p className="text-4xl inline">🎅</p>
                                    <br/>
                                    <p>Viele Grüße!</p>
                                    <p>Ihr BN-Server Team</p>
                                    <br/>
                                </div>
                            </span>
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }
}

export default Blog1;