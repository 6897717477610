import React, {Component} from 'react';
import Navbar from "../../components/base/Navbar";
import Footer from "../../components/base/Footer";

class Blog3 extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <Navbar/>

                <div
                    className="bg-neutral-700 rounded-3xl border-4 border-neutral-600 mx-12 my-8 p-5 px-24 justify-center">

                    <div className="text-base mt-6 mb-6 text-neutral-200">
                            <span className="prose prose-invert">
                                <h1 className="text-4xl text-center font-bold">Lobby JumpAndRun #3</h1>
                                <p className="text-neutral-400 text-center font-bold">21. August 2024</p>
                                <br/>
                                 <img
                                     src="https://i.imgur.com/IDvOgP7.png"
                                     alt="lobby.webp"
                                     className="w-full h-full object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400 block ml-auto mr-auto"/>

                                <br/>
                                <div className="px-24 text-xl">
                                    <p>
                                        Es ist schon eine Weile her, dass es ein Update zum BlackNinja.Live Netzwerk gab, aber ich kann euch mitteilen,
                                        dass wir schon viel von unserem Vorhaben geschafft haben und es nicht mehr lange bis zum ersten Beta Release dauert!
                                    </p>
                                    <br/>
                                    <p>
                                        Und das nächste Update ist für diejenigen interessant (Auch für alle anderen :peepoLove~5: ), die sich oft langweilen und
                                        ihre Jump And Run Skills ein wenig verbessern wollen. Dafür haben wir die Lösung, ab sofort steht in der Lobby ein JumpAndRun
                                        für euch bereit! Dies werdet ihr im Navigator unter dem grünen Item finden. Also Springt los und brecht Rekorde!
                                    </p>
                                    <br/>
                                    <p>Viele Grüße!</p>
                                    <p>Ihr BN-Server Team</p>
                                    <br/>
                                </div>
                            </span>
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }
}

export default Blog3;