import React, {Component} from 'react';
import Navbar from "../components/base/Navbar";
import BackgroundAndLogo from "../components/BackgroundAndLogo";
import ServerStats from "../components/ServerStats";
import HowToConnect from "../components/HowToConnect";
import GamemodesCount from "../components/serverstats/GamemodesCount";
import Gamemodes from "../components/Gamemodes";
import Footer from "../components/base/Footer";
import Blog from "../components/Blog";
import MainNavbar from "../components/base/MainNavbar";

class Main extends Component {
    render() {
        return (
            <div className="bg-dots bg-neutral-800 selection:bg-cyan-400 selection:text-white bg-center">

                <MainNavbar/>

                <BackgroundAndLogo/>

                <div className="w-full m-12"></div>

                <ServerStats/>

                <div className="w-full m-12"></div>

                <HowToConnect/>

                <div className="w-full m-12"></div>

                <Gamemodes/>

                <div className="w-full m-12"></div>

                <Blog/>

                <div className="w-full m-12"></div>

                <div className="flex justify-center">
                    <div
                        className="my-auto italic text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500
                        group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none
                        focus:ring-cyan-200 dark:focus:ring-cyan-800 px-4 py-2 font-bold hover:ring-4 text-xl">
                        <a href="https://discord.blackNinja.live">Hast du noch Fragen? Komm auf unseren Discord!</a>
                    </div>
                </div>

                <div className="w-full m-12"></div>

                <Footer/>

            </div>
        );
    }
}

export default Main;