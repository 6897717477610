import React, {Component} from 'react';
import RegisteredPlayers from "./serverstats/RegisteredPlayers";
import GlobalPlaytime from "./serverstats/GlobalPlaytime";
import GamemodesCount from "./serverstats/GamemodesCount";

class ServerStats extends Component {
    render() {
        return (
            <div>
                <div className="container mx-auto">
                    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        <RegisteredPlayers />
                        <GlobalPlaytime />
                        <GamemodesCount />
                    </div>
                </div>
            </div>
        );
    }
}

export default ServerStats;