import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad } from '@fortawesome/fontawesome-free-solid'

class GamemodesCount extends Component {
    render() {
        return (
            <div>
                <div className="bg-cyan-400 shadow-lg rounded-3xl overflow-hidden border-4 border-cyan-300">
                    <div className="flex items-center p-6 drop-shadow-md">
                        <div className="w-16 h-16 flex justify-center items-center bg-neutral-700 rounded-full text-white text-3xl">
                            <FontAwesomeIcon icon={faGamepad} />
                        </div>
                        <div className="ml-4">
                            <h3 className="text-2xl font-semibold text-white drop-shadow-md">Spielmodies</h3>
                            <p className="text-3xl font-bold text-gray-900">3</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GamemodesCount;