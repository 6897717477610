import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/fontawesome-free-solid'

class HowToConnect extends Component {
    render() {
        return (
            <div>
                <div className="container mx-auto px-4 py-8 bg-neutral-700 rounded-3xl space-y-8 shadow-md border-4 border-neutral-600">
                    <div className="flex flex-col lg:flex-row items-center lg:items-start rounded-lg">
                        <div className="w-full lg:w-2/5 flex justify-center items-center p-4 drop-shadow-lg">
                            <img src="https://i.imgur.com/Gac7ZUz.png" alt="Background.webp"
                                 className="object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                        </div>
                        <div className="w-full lg:w-1/2 p-6 text-white">
                            <h2 className="text-3xl font-bold mb-4 text-center text-cyan-400 drop-shadow-md">Wie kommst du auf
                                BlackNinja.Live?</h2>
                            <p className="mb-4 text-2xl">Bist du bereit, um dich ins Abenteuer zu stürzen? Dann befolge diese
                                Anleitung:</p>
                            <ol className="list-decimal list-inside mb-4 text-2xl">
                                <li>Starte Minecraft und wähle den Multiplayer.</li>
                                <li>Klicke auf Server hinzufügen.</li>
                                <li>Trage die Serveradresse ein: <span className="main-ip cursor-pointer font-bold text-cyan-400 hover:text-cyan-700" id="HTC-ip" onClick={copyIP}>BlackNinja.Live
                                    <FontAwesomeIcon className="mx-1" icon={faCopy} />
                                </span>
                                </li>
                                <li>Klicke auf Fertig und mache einen Doppelklick auf den Server!</li>
                            </ol>
                            <p className="italic text-2xl opacity-75">Bei Problemen wenden Sie sich bitte an unseren Support.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function copyIP() {
    const copyText = "BlackNinja.Live";
    navigator.clipboard.writeText(copyText).then(r =>
        setText()
    )
}

function setText() {
    document.getElementById("HTC-ip").innerHTML = "Kopiert!"
    setTimeout(() => {
        window.location.reload();
    }, 1000 * 2);
}

export default HowToConnect;